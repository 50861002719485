<template>
  <div>
    <b-form-group
      label="Nombre:"
      label-for="input-name"
      label-cols="0"
      label-cols-sm="3"
      label-cols-md="3"
      class="m-0 p-0"
    >
      <b-form-input
        id="input-name"
        v-model="$v.evaluation.name.$model"
        :state="validateState('name')"
        aria-describedby="input-name-feedback"
        size="sm"
      ></b-form-input>
      <b-form-invalid-feedback id="input-name-feedback"
        >Este campo es obligatorio.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Descripción:"
      label-for="input-description"
      label-class="mt-0"
      label-cols="0"
      label-cols-sm="3"
      label-cols-md="3"
      class="m-0 p-0"
    >
      <div
        v-b-tooltip.v-secondary="'Editar Descripción'"
        class="evaluation-form-description rich-text-content mr-0 mt-1"
        v-html="$v.evaluation.description.$model"
        @click="
          $bvModal.show(`edit-evaluation-description-modal-${evaluation.id}`)
        "
      ></div>
      <b-modal
        :id="`edit-evaluation-description-modal-${evaluation.id}`"
        title="Editar Descripción"
        size="lg"
        hide-footer
      >
        <NewRichTextEditor
          :Object="evaluation"
          :Text="evaluation.description"
          @save="patchEvaluation"
          @close="
            $bvModal.hide(`edit-evaluation-description-modal-${evaluation.id}`)
          "
        ></NewRichTextEditor>
      </b-modal>
      <b-form-invalid-feedback id="input-description-feedback"
        >Este campo es opcional.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Descripción para el estudiante:"
      label-for="input-description_for_student"
      label-class="mt-0"
      label-cols="0"
      label-cols-sm="3"
      label-cols-md="3"
      class="m-0 p-0"
    >
      <div
        v-b-tooltip.v-secondary="'Editar Descripción para el estudiante'"
        class="evaluation-form-description rich-text-content mr-0 mt-1"
        v-html="$v.evaluation.description_for_student.$model"
        @click="
          $bvModal.show(
            `edit-evaluation-description_for_student-modal-${evaluation.id}`
          )
        "
      ></div>
      <b-modal
        :id="`edit-evaluation-description_for_student-modal-${evaluation.id}`"
        title="Editar Descripción para el estudiante"
        size="lg"
        hide-footer
      >
        <NewRichTextEditor
          :Object="evaluation"
          :Text="evaluation.description_for_student"
          @save="patchDescriptionForStudent"
          @close="
            $bvModal.hide(
              `edit-evaluation-description_for_student-modal-${evaluation.id}`
            )
          "
        ></NewRichTextEditor>
      </b-modal>
      <b-form-invalid-feedback id="input-description-feedback"
        >Este campo es opcional.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Actividad o Evaluación:"
      label-for="select-type"
      label-cols="0"
      label-cols-sm="3"
      label-cols-md="3"
      class="m-0 p-0 mt-2"
    >
      <b-form-select
        id="select-type"
        v-model="$v.evaluation.type.$model"
        :options="EvaluationTypeFilter"
        value-field="id"
        text-field="value"
        @change="changeEvaluationType()"
        :state="validateState('type')"
        aria-describedby="select-type-feedback"
        size="sm"
      ></b-form-select>
      <b-form-invalid-feedback id="select-type-feedback"
        >Este campo es obligatorio.</b-form-invalid-feedback
      >
    </b-form-group>
    <!-- v-if="institution.internal_use_id == 'duoc_uc' || $debug_change_duoc" -->
    <template>
      <template v-if="evaluation.type != 4">
        <b-form-group
          :label="
            $getVisibleNames(
              'teaching.evaluationsituation',
              false,
              'Situación Evaluativa'
            ) + ':'
          "
          label-for="select-evaluation_situations"
          label-cols="0"
          label-cols-sm="3"
          label-cols-md="3"
          class="m-0 p-0"
        >
          <v-select
            id="input-evaluation_situations"
            v-model="$v.evaluation.evaluation_situations.$model"
            :options="evaluation_situations"
            :reduce="(evaluation_situations) => evaluation_situations.id"
            :placeholder="`${
              evaluation_situations.length > 0
                ? String(
                    'Seleccione uno o varias ' +
                      $getVisibleNames(
                        'teaching.evaluationsituation',
                        true,
                        'Situaciones Evaluativas'
                      )
                  )
                : String(
                    'No cuenta con ' +
                      $getVisibleNames(
                        'teaching.evaluationsituation',
                        true,
                        'Situaciones Evaluativas'
                      )
                  )
            }`"
            multiple
            :dropdown-should-open="dropdownShouldOpen4"
            :selectable="
              (option) => !evaluation.evaluation_situations.includes(option.id)
            "
            :state="validateState('evaluation_situations')"
            label="name"
            track-by="id"
            size="sm"
            class="mt-1"
          >
            <template v-slot:no-options="{ search, searching }">
              <template v-if="searching">
                No se encontraron resultados para: "<em>{{ search }}</em
                >"
              </template>
            </template>
          </v-select>
          <b-form-invalid-feedback id="select-evaluation_situations-feedback"
            >Este campo es obligatorio.</b-form-invalid-feedback
          >
        </b-form-group>
      </template>
      <b-form-group
        label="Agente Evaluativo:"
        v-if="this.evaluation.type != 4"
        label-for="select-evaluative_agent"
        label-cols="0"
        label-cols-sm="3"
        label-cols-md="3"
        class="m-0 p-0"
      >
        <v-select
          id="input-evaluative_agents"
          v-model="$v.evaluation.evaluative_agents.$model"
          :options="evaluative_agent_types"
          :reduce="(evaluative_agent_types) => evaluative_agent_types.id"
          :placeholder="`${
            evaluative_agent_types.length > 0
              ? String('Seleccione uno o varios Agentes Evaluativos')
              : String('No cuenta con Agentes Evaluativos')
          }`"
          multiple
          :dropdown-should-open="dropdownShouldOpen5"
          :selectable="
            (option) => !evaluation.evaluative_agents.includes(option.id)
          "
          :state="validateState('evaluative_agents')"
          label="name"
          track-by="id"
          size="sm"
          class="mt-1"
        >
          <template v-slot:no-options="{ search, searching }">
            <template v-if="searching">
              No se encontraron resultados para: "<em>{{ search }}</em
              >"
            </template>
          </template>
        </v-select>
        <b-form-invalid-feedback id="select-evaluative_agent-feedback"
          >Este campo es obligatorio.</b-form-invalid-feedback
        >
      </b-form-group>
      <b-form-group
        v-if="this.evaluation.type != 4"
        label="Tipo de Trabajo:"
        label-for="select-work_type"
        label-cols="0"
        label-cols-sm="3"
        label-cols-md="3"
        class="m-0 p-0"
      >
        <b-form-select
          id="select-work_type"
          v-model="$v.evaluation.work_type.$model"
          :options="WorkTypes"
          value-field="id"
          text-field="value"
          :state="validateState('work_type')"
          aria-describedby="select-work_type-feedback"
          size="sm"
          class="mt-1"
        ></b-form-select>
        <b-form-invalid-feedback id="select-work_type-feedback"
          >Este campo es obligatorio.</b-form-invalid-feedback
        >
      </b-form-group>
      <b-form-group
        v-if="this.evaluation.type != 4"
        label="Examinador:"
        label-for="select-examiner"
        label-cols="0"
        label-cols-sm="3"
        label-cols-md="3"
        class="m-0 p-0"
      >
        <b-form-select
          id="select-examiner"
          v-model="$v.evaluation.examiner.$model"
          :options="ExaminerTypes"
          value-field="id"
          text-field="value"
          :state="validateState('examiner')"
          aria-describedby="select-examiner-feedback"
          size="sm"
          class="mt-1"
        ></b-form-select>
        <b-form-invalid-feedback id="select-examiner-feedback"
          >Este campo es obligatorio.</b-form-invalid-feedback
        >
      </b-form-group>
      <b-form-group
        label-cols="0"
        label-cols-sm="3"
        label="N° Estudiantes:"
        label-for="input-suggested-suggested_students"
        class="label my-2 mx-0"
      >
        <b-form-input
          id="input-suggested-suggested_students"
          type="number"
          min="0"
          v-model="$v.evaluation.suggested_students.$model"
          :state="validateState('suggested_students')"
          aria-describedby="input-suggested-suggested_students-feedback"
          size="sm"
          class="mt-1"
        ></b-form-input>
        <b-form-invalid-feedback
          id="input-suggested-suggested_students-feedback"
          >Este campo debe ser mayor o igual a cero.</b-form-invalid-feedback
        >
      </b-form-group>
      <b-form-group
        v-if="studyEnvironments.length > 0"
        label-cols="0"
        label-cols-sm="3"
        :label="
          $getVisibleNames(
            'mesh.studyenvironment',
            false,
            'Ambiente de Aprendizaje'
          ) + ':'
        "
        label-for="input-study_environments"
        class="label my-2 mx-0"
      >
        <v-select
          id="input-study_environments"
          v-model="$v.evaluation.study_environments.$model"
          :options="studyEnvironmentsList"
          :reduce="(studyEnvironmentsList) => studyEnvironmentsList.id"
          @input="changeStudyEnvironment"
          :placeholder="`${
            studyEnvironmentsList.length > 0
              ? String(
                  'Seleccione uno o varios ' +
                    $getVisibleNames(
                      'mesh.studyenvironment',
                      false,
                      'Ambiente de Aprendizaje'
                    ).toLowerCase()
                )
              : String(
                  'No cuenta con ' +
                    $getVisibleNames(
                      'mesh.studyenvironment',
                      false,
                      'Ambiente de Aprendizaje'
                    ).toLowerCase() +
                    ' creadas'
                )
          }`"
          multiple
          :dropdown-should-open="dropdownShouldOpen2"
          :selectable="
            (option) => !evaluation.study_environments.includes(option.id)
          "
          :state="validateState('study_environments')"
          label="name"
          track-by="id"
          size="sm"
          class="mt-1"
        >
          <template v-slot:no-options="{ search, searching }">
            <template v-if="searching">
              No se encontraron resultados para: "<em>{{ search }}</em
              >"
            </template>
          </template>
        </v-select>
        <b-tooltip
          target="input-study_environments"
          variant="secondary"
          :noninteractive="true"
          triggers="hover"
        >
          <div v-if="studyEnvironmentsList.length == 0">
            Diríjase a Definiciones Curriculares para poder crear
            {{
              $getVisibleNames(
                "mesh.studyenvironment",
                false,
                "Ambiente de Aprendizaje"
              ).toLowerCase()
            }}.
          </div>
        </b-tooltip>
      </b-form-group>
      <b-form-group
        label-cols="0"
        label-cols-sm="3"
        label="Horas Docencia Directa (Horas Pedagógicas):"
        label-for="input-suggested-suggested_hours"
        class="label my-2 mx-0"
      >
        <MatEvaStuEnvTimAllComponent
          :key="key_ep_mat_eva_stu_env_tim_all"
          :matter_id="egress_profile_matter_id"
          :matter_evaluation_id="evaluation.id"
          :StudyEnvironmentIds="evaluation.study_environments"
          :MatEvaStuEnvTimAll="EPMatEvaStuEnvTimAll"
          :tmpMatEvaStuEnvTimAll="tmp_ep_mat_eva_stu_env_tim_all"
          :isPedagogical="true"
          :isOlMatter="false"
          @created="createdEPMatStuEnvTimAll"
          @updated="UpdateEPMatStuEnvTimAll"
          @deleted="deletedEPMatStuEnvTimAll"
        >
        </MatEvaStuEnvTimAllComponent>
      </b-form-group>
      <b-form-group
        label-cols="0"
        label-cols-sm="3"
        label="Horas de Trabajo Autónomo (Horas cronológicas):"
        label-for="input-suggested-autonomous_work_hours"
        class="label my-2 mx-0"
      >
        <MatEvaStuEnvTimAllComponent
          :key="key_ep_mat_eva_stu_env_tim_all"
          :matter_id="egress_profile_matter_id"
          :matter_evaluation_id="evaluation.id"
          :StudyEnvironmentIds="evaluation.study_environments"
          :MatEvaStuEnvTimAll="EPMatEvaStuEnvTimAll"
          :tmpMatEvaStuEnvTimAll="tmp_ep_mat_eva_stu_env_tim_all"
          :isPedagogical="false"
          :isOlMatter="false"
          @created="createdEPMatStuEnvTimAll"
          @updated="UpdateEPMatStuEnvTimAll"
          @deleted="deletedEPMatStuEnvTimAll"
        >
        </MatEvaStuEnvTimAllComponent>
      </b-form-group>
      <b-form-group
        label="Actividad de Trabajo Autónomo del/la estudiante:"
        label-for="input-autonomous_activities"
        label-cols="0"
        label-cols-sm="3"
        label-cols-md="3"
        class="m-0 p-0"
      >
        <div class="d-flex">
          <div class="d-block w-100">
            <v-select
              id="input-autonomous_activities"
              v-model="$v.evaluation.autonomous_activities.$model"
              :options="autonomous_teaching_activities"
              :reduce="
                (autonomous_teaching_activities) =>
                  autonomous_teaching_activities.id
              "
              :placeholder="`${
                autonomous_teaching_activities.length > 0
                  ? String(
                      'Seleccione uno o varias Actividades de Trabajo Autónomo'
                    )
                  : String('No cuenta con Actividades de Trabajo Autónomo')
              }`"
              multiple
              :dropdown-should-open="dropdownShouldOpen3"
              :selectable="
                (option) =>
                  !evaluation.autonomous_activities.includes(option.id)
              "
              :state="validateState('autonomous_activities')"
              label="name"
              track-by="id"
              size="sm"
              class="mt-1"
            >
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  No se encontraron resultados para: "<em>{{ search }}</em
                  >"
                </template>
              </template>
            </v-select>
          </div>
          <button
            v-can="'mesh.add_autonomousteachingactivity'"
            @click="openModalAutonomousTeachingActivity"
            class="btn-add"
            v-b-tooltip.v-secondary.noninteractive="
              `Agregar ${$getVisibleNames(
                'mesh.autonomousteachingactivity',
                false,
                'Actividad de Trabajo Autónomo'
              )}
            `
            "
          >
            <b-icon font-scale="1" icon="plus-square"></b-icon>
          </button>
        </div>
      </b-form-group>
    </template>
    <b-form-group
      v-if="evaluation.type == 3 || evaluation.type == 5"
      label="Ponderación:"
      label-for="input-preferred_weighing"
      label-cols="4"
      label-cols-sm="3"
      class="m-0 p-0"
      :description="`${
        parseFloat(maxWeighing) >= 0 && evaluation.weighing >= 0
          ? 'Disponible ' + maxWeighing + '%'
          : ''
      }`"
    >
      <b-form-input
        id="input-weighing"
        v-model="$v.evaluation.weighing.$model"
        :disabled="!evaluation.type == 3 || !evaluation.type == 5"
        :state="validateState('weighing')"
        type="number"
        aria-describedby="input-weighing-feedback"
        size="sm"
        min="0"
        class="mt-1"
      ></b-form-input>
      <b-form-invalid-feedback id="input-weighing-feedback">
        <template>
          <span v-if="evaluation.weighing < 0">Debe ser Mayor que 0 y</span>
          <span v-if="evaluation.weighing == ''"
            >Este campo es obligatorio y</span
          >
          No debe exceder
          {{
            maxWeighing >= 0
              ? maxWeighing
              : maxWeighing + parseFloat(evaluation.weighing)
          }}%
          <!-- <span v-if="maxWeighing > 100">
            Las ponderaciones suman más del 100%
          </span>
          <span v-else-if="evaluation.weighing <= 0">
            Este campo debe ser mayor de 0.
          </span>
          <template v-else> Este campo es requerido.</template> -->
        </template>
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group
      v-if="institution && institution.show_evaluation_criteria_macro"
      :label="`${$getVisibleNames(
        'teaching.evaluationcriteriamacro',
        true,
        'Criterios de Evaluación Macro'
      )}:`"
      label-for="select-evaluation-criterias-macro"
      label-cols="0"
      label-cols-sm="3"
      label-class="ml-1"
      class="custom-mr"
    >
      <template v-if="evaluationCriteriasMacrosFilter.length > 0">
        <b-form-select
          id="select-evaluation-criterias-macro"
          v-model="evaluation.evaluation_criterias_macro"
          multiple
          :select-size="
            evaluationCriteriasMacrosFilter.length > 6
              ? 6
              : evaluationCriteriasMacrosFilter.length + 1
          "
          size="sm"
          class="select-form mt-2"
        >
          <b-form-select-option
            class="select-option-macro"
            v-for="item in evaluationCriteriasMacrosFilter"
            :key="item.id"
            :value="item.id"
          >
            {{ item.order }} - {{ item.full_sentence }}
          </b-form-select-option>
        </b-form-select>
      </template>
      <template v-else>
        <div>N/A</div>
      </template>
    </b-form-group>
    <template
      v-if="
        institution &&
        (institution.internal_use_id == 'duoc_uc' ||
          institution.internal_use_id == 'ciisa_uss' ||
          $debug_change_duoc) &&
        evaluation.type != 5
      "
    >
      <b-form-group
        :label="`${$getVisibleNames(
          'teaching.learningexperience',
          false,
          'Experiencia de Aprendizaje'
        )}:`"
        label-for="select-learning-experience"
        label-cols="0"
        label-cols-sm="3"
        label-class="ml-1"
        class="custom-mr"
      >
        <b-form-select
          id="select-learning-experience"
          v-model="evaluation.learning_experience"
          :state="validateState('learning_experience')"
          aria-describedby="input-suggested-learning_experience-feedback"
          size="sm"
          class="select-form mt-2"
          @change="changeLearningExperience"
        >
          <template #first>
            <b-form-select-option :value="null">
              --- Seleccione una de las opciones ---
            </b-form-select-option>
          </template>
          <b-form-select-option
            v-for="learning_experience in learningExperiencesList"
            :value="learning_experience.id"
            :key="learning_experience.id"
          >
            {{ learning_experience.name }}
          </b-form-select-option>
        </b-form-select>
        <b-form-invalid-feedback
          id="input-suggested-learning_experience-feedback"
          >Este campo es requerido.</b-form-invalid-feedback
        >
      </b-form-group>
      <!-- <b-form-group
        :label="`${$getVisibleNames('teaching.ramicro', false, 'RA Micro')}:`"
        label-for="select-ra-micro"
        label-cols="0"
        label-cols-sm="3"
        label-class="ml-1"
        class="custom-mr"
      >
        <b-form-select
          v-if="studyUnitsList.length > 0"
          id="select-ra-micro"
          v-model="selected_study_unit"
          size="sm"
          class="select-form mt-2"
          @change="() => (evaluation.evaluation_criterias_micro = [])"
        >
          <b-form-select-option
            v-for="ecm in studyUnitsList"
            :value="ecm.id"
            :key="ecm.id"
          >
            N° {{ ecm.order }}: {{ ecm.name }}
          </b-form-select-option>
        </b-form-select>
        <template v-else>
          <div>N/A</div>
        </template>
      </b-form-group> -->
    </template>
    <b-form-group
      :label="`${$getVisibleNames(
        'teaching.evaluationcriteriamicro',
        true,
        'Criterios de Evaluación Micro'
      )}:`"
      label-for="select-evaluation-criterias-micro"
      label-cols="0"
      label-cols-sm="3"
      label-class="ml-1"
      class="custom-mr"
    >
      <template v-if="filterECMicro.length > 0">
        <b-form-select
          id="select-evaluation-criterias-micro"
          v-model="evaluation.evaluation_criterias_micro"
          :select-size="lengthFilterECMicro > 8 ? 8 : lengthFilterECMicro"
          size="sm"
          multiple
          class="select-form mt-2"
        >
          <b-form-select-option-group
            v-for="item in filterECMicro"
            :key="item.id"
            :label="item.name"
          >
            <b-form-select-option
              class="select-option-micro"
              v-for="ecm in item.options"
              :value="ecm.id"
              :key="ecm.id"
              :title="ecm.name"
            >
              {{
                item.competence_unit_order != 999
                  ? item.competence_unit_order + "."
                  : ""
              }}{{ item.order }}.{{ ecm.order }} {{ ecm.full_sentence }}
            </b-form-select-option>
          </b-form-select-option-group>
        </b-form-select>
      </template>
      <template v-else>
        <div>N/A</div>
      </template>
    </b-form-group>
    <!-- v-if="institution.internal_use_id == 'duoc_uc' || $debug_change_duoc" -->
    <template>
      <b-form-group
        :label="`Recursos de Apoyo:`"
        label-for="teaching-support-resource-select"
        v-if="evaluation.type == 4"
        label-cols="0"
        label-cols-sm="3"
        label-class="ml-1"
        class="custom-mr"
      >
        <div class="d-flex">
          <div class="d-block w-100">
            <template v-if="filterResource.length > 0">
              <b-form-select
                id="teaching-support-resource-select"
                v-model="evaluation.teaching_support_resources"
                :select-size="lengthResource > 7 ? 7 : lengthResource"
                size="sm"
                multiple
                class="select-form mt-1"
              >
                <b-form-select-option-group
                  v-for="item in filterResource"
                  :key="item.id"
                  :label="item.name"
                >
                  <b-form-select-option
                    class="select-option-micro"
                    v-for="ecm in item.options"
                    :value="ecm.id"
                    :key="ecm.id"
                    :title="ecm.name"
                    v-html="ecm.name"
                  >
                  </b-form-select-option>
                </b-form-select-option-group>
              </b-form-select>
            </template>
            <template v-else>
              <div>N/A</div>
            </template>
          </div>
          <!-- v-can="'mesh.add_teachingsupportresourcetype'" -->
          <button
            @click="$bvModal.show(`new-teaching-support-resource-modal`)"
            class="btn-add"
            v-b-tooltip.v-secondary.noninteractive="`Agregar Recursos de Apoyo`"
          >
            <b-icon font-scale="1" icon="plus-square"></b-icon>
          </button>
        </div>
      </b-form-group>
    </template>
    <div style="text-align: right">
      <b-button
        v-if="!isNaN(evaluation.id)"
        size="sm"
        fill="white"
        class="mx-2"
        v-b-tooltip.v-secondary.noninteractive.top="
          'Editar Recursos Bibliográficos'
        "
        @click="
          $bvModal.show(`epm-bibliographic-resource-modal-${evaluation.id}`)
        "
      >
        <b-icon class="mr-2" icon="book"></b-icon>Recursos Bibliográficos
      </b-button>
      <b-button class="mr-1" size="sm" @click="save">Guardar</b-button>
    </div>
    <!-- modal -->
    <b-modal
      :id="`new-autonomous-teaching-activity-modal`"
      :hide-footer="true"
      :title="`Crear ${$getVisibleNames(
        'mesh.autonomousteachingactivity',
        false,
        'Actividad de Trabajo Autónomo'
      )}`"
      size="md"
    >
      <AutonomousTeachingActivityForm
        @created="slotCreatedAutonomousTeachingActivity"
      ></AutonomousTeachingActivityForm>
    </b-modal>
    <b-modal
      :id="`new-teaching-support-resource-modal`"
      :hide-footer="true"
      :title="`Crear Recursos de Apoyo`"
      size="lg"
      @hide="
        () => {
          selected_resource_type = null;
        }
      "
    >
      <b-form-group
        label="Recursos de Apoyo:"
        label-for="select-teaching-support-resource"
        label-cols="0"
        label-cols-sm="3"
        label-cols-md="3"
        class="m-0 p-0 mb-2"
      >
        <b-form-select
          id="select-teaching-support-resource"
          v-model="selected_resource_type"
          :options="filterResourceTypes"
          value-field="id"
          text-field="name"
          :state="selected_resource_type != null"
          @change="
            () => {
              if (selected_resource_type != null)
                $bvModal.show(
                  `create-resource-type-modal-${selected_resource_type}`
                );
            }
          "
          aria-describedby="select-teaching-support-resource-feedback"
          size="sm"
          class="mt-1"
        ></b-form-select>
        <b-form-invalid-feedback id="select-teaching-support-resource-feedback"
          >Este campo es obligatorio.</b-form-invalid-feedback
        >
      </b-form-group>
    </b-modal>
    <b-modal
      :id="`create-resource-type-modal-${selected_resource_type}`"
      title="Crear Recurso Apoyo"
      size="lg"
      @hide="
        () => {
          selected_resource_type = null;
          $bvModal.hide(`new-teaching-support-resource-modal`);
        }
      "
      hide-footer
    >
      <NewRichTextEditor
        :Type="{ id: selected_resource_type }"
        @save="createSupportResource"
        @close="
          $bvModal.hide(`create-resource-type-modal-${selected_resource_type}`)
        "
      ></NewRichTextEditor>
    </b-modal>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import {
  required,
  minValue,
  numeric,
  maxValue,
} from "vuelidate/lib/validators";
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { generateUniqueId } from "@/utils/utils";
import "vue-multiselect/dist/vue-multiselect.min.css";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
export default {
  name: "EPMatterProgramEvaluationForm",
  mixins: [validationMixin],
  components: {
    vSelect,
    AutonomousTeachingActivityForm: () =>
      import(
        "@/components/mesh/MethodologicalResources/AutonomousTeachingActivity/AutonomousTeachingActivityForm.vue"
      ),
    MatEvaStuEnvTimAllComponent: () =>
      import(
        "@/components/teaching/MatterProgram/Components/MatEvaStuEnvTimAllComponent.vue"
      ),
    NewRichTextEditor: () => import("@/components/reusable/NewRichTextEditor"),
  },
  props: {
    Evaluation: {
      type: Object,
    },
    egress_profile_matter_id: {
      type: Number,
      required: true,
    },
    EvaluationSituations: {
      type: Array,
    },
    ExaminerTypes: {
      type: Array,
    },
    EvaluativeAgentTypes: {
      type: Array,
    },
    WorkTypes: {
      type: Array,
    },
    EPMatEvaStuEnvTimAll: {
      type: Array,
    },
    evaluation_list: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      evaluation: {
        id: this.Evaluation ? this.Evaluation.id : generateUniqueId(),
        name: this.Evaluation ? this.Evaluation.name : "",
        description: this.Evaluation ? this.Evaluation.description : "",
        description_for_student: this.Evaluation
          ? this.Evaluation.description_for_student
          : "",
        study_environments: this.Evaluation
          ? this.Evaluation.study_environments
          : [],
        type: this.Evaluation ? this.Evaluation.type : null,
        learning_experience: this.Evaluation
          ? this.Evaluation.learning_experience
          : null,
        evaluative_agents: this.Evaluation
          ? this.Evaluation.evaluative_agents
          : [],
        examiner: this.Evaluation ? this.Evaluation.examiner : 3,
        work_type: this.Evaluation ? this.Evaluation.work_type : 1,
        evaluation_situations: this.Evaluation
          ? this.Evaluation.evaluation_situations
          : [],
        suggested_hours: this.Evaluation ? this.Evaluation.suggested_hours : 0,
        teaching_support_resources: this.Evaluation
          ? this.Evaluation.teaching_support_resources
          : [],
        egress_profile_matter: this.egress_profile_matter_id,
        weighing: this.Evaluation ? this.Evaluation.weighing : 0,
        order: this.Evaluation
          ? this.Evaluation.order
          : this.evaluation_list.length + 1,
        evaluation_criterias_macro: this.Evaluation
          ? this.Evaluation.evaluation_criterias_macro
          : [],
        evaluation_criterias_micro: this.Evaluation
          ? this.Evaluation.evaluation_criterias_micro
          : [],
        autonomous_activities: this.Evaluation
          ? this.Evaluation.autonomous_activities
          : [],
        suggested_students: this.Evaluation
          ? this.Evaluation.suggested_students
          : 0,
        autonomous_work_hours: this.Evaluation
          ? this.Evaluation.autonomous_work_hours
          : 0,
        mandatory_bibliographic_resources: this.Evaluation
          ? this.Evaluation.mandatory_bibliographic_resources
          : [],
        supplementary_bibliographic_resources: this.Evaluation
          ? this.Evaluation.supplementary_bibliographic_resources
          : [],
      },
      tmp_ep_mat_eva_stu_env_tim_all: [],
      key_ep_mat_eva_stu_env_tim_all: 0,
      selected_resource_type: null,
      teaching_resources: [],
      teaching_resource_types: [],
      evaluation_situations: this.EvaluationSituations,
      evaluative_agent_types: this.EvaluativeAgentTypes,
      // learning_experiences: [],
    };
  },
  validations: {
    evaluation: {
      name: { required },
      description: {},
      description_for_student: {},
      type: { required },
      evaluation_situations: {},
      evaluative_agent: {},
      evaluative_agents: {},
      examiner: {},
      work_type: {},
      learning_experience: {
        is_valid() {
          if (
            ((this.institution &&
              this.institution.internal_use_id == "duoc_uc") ||
              this.$debug_change_duoc) &&
            this.evaluation.type != 5
          ) {
            if (this.evaluation.learning_experience == null) return false;
            else return true;
          } else return true;
        },
      },
      suggested_hours: {
        // numeric,
        // minValue: minValue(0),
        // is_valid() {
        // if (
        //   (this.institution && this.institution.internal_use_id == 'duoc_uc') ||
        //   this.$debug_change_duoc
        // ) {
        // if (this.max_suggested_hours >= 0) return true;
        // else return false;
        // } else return true;
        // },
      },
      suggested_students: { numeric, minValue: minValue(0) },
      autonomous_work_hours: {
        //   numeric,
        //   minValue: minValue(0),
      },
      autonomous_activities: {},
      weighing: {
        required,
        numeric,
        minValue: minValue(0),
        maxValue: maxValue(100),
        is_valid() {
          if (this.evaluation.type == 3 || this.evaluation.type == 5)
            if (this.maxWeighing >= 0) return true;
            else return false;
          else if (
            this.evaluation.type != 3 &&
            this.evaluation.type != 5 &&
            this.evaluation.weighing == 0
          )
            return true;
          else return false;
        },
      },
      study_environments: {},
      teaching_support_resources: {},
      order: {
        // required,
        // numeric,
        // minValue: minValue(1),
      },
    },
  },
  computed: {
    ...mapGetters({
      institution: "getInstitution",
      time_allocations: names.TIME_ALLOCATIONS,
      ep_matter_time_allocations: names.EP_MATTER_TIME_ALLOCATIONS,
      egress_profiles_matters: names.EGRESS_PROFILES_MATTERS,
      matters: names.MATTERS,
      evaluationCriteriasMacros: names.EVALUATION_CRITERIAS_MACROS,
      evaluationCriteriasMicros: names.EVALUATION_CRITERIAS,
      autonomous_teaching_activities: names.AUTONOMOUS_TEACHING_ACTIVITIES,
      studyEnvironments: names.STUDY_ENVIRONMENTS,
      studyUnits: names.STUDY_UNITS,
      learningExperiences: names.LEARNING_EXPERIENCES,
      epm_evaluation_types: names.EPM_EVALUATION_TYPES,
      competence_units: names.COMPETENCE_UNITS,
    }),
    matter() {
      return this.egress_profiles_matters.find(
        (x) => x.id == this.egress_profile_matter_id
      );
    },
    filterLearningExperience() {
      if (!this.matter) return [];
      return this.learningExperiences.filter(
        (x) => x.matter == this.matter.matter
      );
    },
    oldMatter() {
      if (!this.matter) return null;
      return this.matters.find((x) => x.id == this.matter.matter);
    },
    EvaluationTypeFilter() {
      const sortByValue = (a, b) => a.value.localeCompare(b.value);

      if (isNaN(this.evaluation.id)) {
        // Al Crear.
        if (this.evaluation_list.filter((x) => x.type == 5).length > 0) {
          return [...this.epm_evaluation_types]
            .filter((x) => [1, 2, 3, 4].includes(x.id))
            .sort(sortByValue);
        } else return [...this.epm_evaluation_types].sort(sortByValue);
      } else {
        if (this.evaluation.type != 4) {
          // !Actividad
          if (
            this.evaluation_list.filter(
              (x) => x.type == 5 && x.id != this.evaluation.id
            ).length > 0
          ) {
            return [...this.epm_evaluation_types]
              .filter((x) => [1, 2, 3].includes(x.id))
              .sort(sortByValue);
          } else {
            return [...this.epm_evaluation_types]
              .filter((x) => [1, 2, 3, 5].includes(x.id))
              .sort(sortByValue);
          }
        } else {
          // Actividad
          if (
            this.evaluation_list.filter(
              (x) => x.type == 5 && x.id != this.evaluation.id
            ).length > 0
          ) {
            return [...this.epm_evaluation_types]
              .filter((x) => [1, 2, 3, 4].includes(x.id))
              .sort(sortByValue);
          }
        }
        return [...this.epm_evaluation_types].sort(sortByValue);
      }
    },
    matterHours() {
      let list = [];
      this.time_allocations.forEach((element) => {
        const matter_hour = this.ep_matter_time_allocations.find(
          (x) =>
            x.egress_profile_matter == this.matter.id &&
            x.time_allocation == element.id
        );
        if (matter_hour)
          list.push({
            id: matter_hour.id,
            time_allocation: matter_hour.time_allocation,
            name: element.name,
            order: element.order,
            uses_module_minutes: element.uses_module_minutes,
            egress_profile_matter: matter_hour.egress_profile_matter,
            counts_towards_credits: matter_hour.counts_towards_credits,
            attendance_requirement: matter_hour.attendance_requirement,
            hours: matter_hour.hours,
            tem_hours:
              parseFloat(this.matter.weeks) > 0
                ? Math.round(
                    parseFloat(matter_hour.hours) /
                      parseFloat(this.matter.weeks)
                  )
                : 0,
          });
      });
      return list;
    },
    studyEnvironmentsList() {
      if (!this.matter || !this.oldMatter) return [];
      if (this.matter.use_matter_values)
        return this.studyEnvironments.filter(
          (x) =>
            this.oldMatter.study_environments.includes(x.id) ||
            this.evaluation.study_environments.includes(x.id)
        );
      else
        return this.studyEnvironments.filter(
          (x) =>
            this.matter.study_environments.includes(x.id) ||
            this.evaluation.study_environments.includes(x.id)
        );
    },
    moduleClassHours() {
      let count = 0;
      this.matterHours
        .filter((x) => x.uses_module_minutes == true)
        .forEach((element) => {
          if (
            element.counts_towards_credits &&
            element.hours &&
            element.hours >= 0
          ) {
            count += parseFloat(element.hours);
          }
        });
      return count;
    },
    // max_suggested_hours() {
    //   let suggested_hours = 0;
    //   this.evaluation_list.forEach((element) => {
    //     if (this.evaluation.id != element.id)
    //       suggested_hours += element.suggested_hours;
    //   });
    //   if (
    //     this.evaluation.suggested_hours &&
    //     this.evaluation.suggested_hours >= 0
    //   )
    //     suggested_hours += parseInt(this.evaluation.suggested_hours);
    //   return this.moduleClassHours - suggested_hours;
    // },
    maxWeighing() {
      let maxWeighing = 0;
      this.evaluation_list.forEach((element) => {
        if (
          (this.institution && this.institution.internal_use_id == "duoc_uc") ||
          this.$debug_change_duoc
        ) {
          if (
            this.evaluation.id != element.id &&
            element.type == this.evaluation.type
          )
            maxWeighing += element.weighing;
        } else {
          if (this.evaluation.id != element.id) maxWeighing += element.weighing;
        }
      });
      if (this.evaluation.weighing && this.evaluation.weighing >= 0)
        maxWeighing += parseInt(this.evaluation.weighing);
      if (
        (this.institution && this.institution.internal_use_id == "duoc_uc") ||
        this.$debug_change_duoc
      ) {
        if (this.evaluation.type == 5) return 40 - maxWeighing;
        else return 100 - maxWeighing;
      } else return 100 - maxWeighing;
    },
    evaluationCriteriasMacrosFilter() {
      return this.evaluationCriteriasMacros
        .filter((x) => x.matter == this.matter.matter)
        .sort(function (a, b) {
          if (a.matter > b.matter) return 1;
          if (a.matter < b.matter) return -1;
          if (a.order > b.order) return 1;
          if (a.order < b.order) return -1;
          return 0;
        });
    },
    learningExperiencesList() {
      if (!this.matter) return [];
      return this.filterLearningExperience
        .filter((x) => x.matter == this.matter.matter)
        .sort(function (a, b) {
          if (a.order > b.order) return 1;
          if (a.order < b.order) return -1;
          return 0;
        });
    },
    studyUnitsList() {
      if (!this.matter) return [];
      return this.studyUnits
        .filter((x) => x.matter == this.matter.matter)
        .sort(function (a, b) {
          if (a.order > b.order) return 1;
          if (a.order < b.order) return -1;
          return 0;
        });
    },
    lengthFilterECMicro() {
      const count = this.filterECMicro.reduce(
        (count, x) => count + x.options.length,
        0
      );
      return count < 2 ? 2 : count;
    },
    filterECMicro() {
      let list = [];
      const learning_experience = this.learningExperiencesList.find(
        (x) => x.id == this.evaluation.learning_experience
      );
      this.studyUnitsList.forEach((study_unit) => {
        let evaluation_criterias = [];
        if (
          this.institution &&
          (this.institution.internal_use_id == "duoc_uc" ||
            this.institution.internal_use_id == "ciisa_uss" ||
            this.$debug_change_duoc) &&
          (this.evaluation.type == 4 ||
            this.evaluation.learning_experience != null)
        ) {
          if (
            learning_experience &&
            learning_experience.study_units.includes(study_unit.id)
          )
            evaluation_criterias = this.evaluationCriteriasMicros.filter(
              (x) => x.study_unit == study_unit.id
            );
        } else
          evaluation_criterias = this.evaluationCriteriasMicros.filter(
            (x) => x.study_unit == study_unit.id
          );
        const competence_unit = this.competence_units.find(
          (c) => c.id == study_unit.temp_competence_unit
        );
        if (evaluation_criterias.length > 0)
          list.push({
            id: study_unit.id,
            name: String(
              "N°" +
                (competence_unit ? competence_unit.order + "." : "") +
                study_unit.order +
                ": " +
                study_unit.name
            ),
            order: study_unit.order,
            competence_unit_order: competence_unit
              ? competence_unit.order
              : 999,
            options: evaluation_criterias.sort(function (a, b) {
              if (a.order > b.order) return 1;
              if (a.order < b.order) return -1;
              return a.full_sentence
                .toLowerCase()
                .localeCompare(b.full_sentence.toLowerCase());
            }),
          });
      });
      return list.sort(function (a, b) {
        if (a.competence_unit_order > b.competence_unit_order) return 1;
        if (a.competence_unit_order < b.competence_unit_order) return -1;
        if (a.order > b.order) return 1;
        if (a.order < b.order) return -1;
        return 0;
      });
    },
    lengthResource() {
      let count = 0;
      this.filterResource.forEach((x) => {
        count += 1; // title
        count += x.options.length;
      });
      return count;
    },
    filterResourceTypes() {
      if (
        (this.institution && this.institution.internal_use_id == "duoc_uc") ||
        this.$debug_change_duoc
      )
        return this.teaching_resource_types.filter(
          (x) =>
            this.$equals(x.name, "RECURSOS DE APRENDIZAJE") ||
            this.$equals(x.name, "TECNOLOGÍA EDUCATIVA")
        );
      else return this.teaching_resource_types;
    },
    filterResource() {
      let list = [];
      this.filterResourceTypes.forEach((resource_type) => {
        let resource = null;
        resource = this.teaching_resources
          .filter((x) => x.type == resource_type.id)
          .sort(function (a, b) {
            if (a.order > b.order) return 1;
            if (a.order < b.order) return -1;
          });
        if (resource.length > 0)
          list.push({
            id: resource_type.id,
            name: resource_type.name,
            options: resource.sort(function (a, b) {
              if (a.id > b.id) return 1;
              if (a.id < b.id) return -1;
            }),
          });
      });
      return list;
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.evaluation[name];
      return $dirty ? !$error : null;
    },
    dropdownShouldOpen2(VueSelect) {
      if (this.studyEnvironmentsList.length > 0) {
        return VueSelect.open;
      }
      return VueSelect.search.length !== 0 && VueSelect.open;
    },
    dropdownShouldOpen3(VueSelect) {
      if (this.autonomous_teaching_activities.length > 0) {
        return VueSelect.open;
      }
      return VueSelect.search.length !== 0 && VueSelect.open;
    },
    dropdownShouldOpen4(VueSelect) {
      if (this.evaluation_situations.length > 0) {
        return VueSelect.open;
      }
      return VueSelect.search.length !== 0 && VueSelect.open;
    },
    dropdownShouldOpen5(VueSelect) {
      if (this.evaluative_agent_types.length > 0) {
        return VueSelect.open;
      }
      return VueSelect.search.length !== 0 && VueSelect.open;
    },
    openModalAutonomousTeachingActivity() {
      this.$bvModal.show(`new-autonomous-teaching-activity-modal`);
    },
    slotCreatedAutonomousTeachingActivity(response) {
      this.evaluation.autonomous_activities.push(response.id);
      this.$bvModal.hide("new-autonomous-teaching-activity-modal");
    },
    patchEvaluation(object, text) {
      this.evaluation.description = text;
    },
    patchDescriptionForStudent(object, text) {
      this.evaluation.description_for_student = text;
    },
    save() {
      this.$v.evaluation.$touch();
      if (this.$v.evaluation.$anyError) {
        return;
      }
      if (isNaN(this.evaluation.id)) this.create();
      else this.update();
    },
    create() {
      if (this.evaluation.study_environments.length > 0) {
        this.$emit(
          "created",
          this.evaluation,
          this.tmp_ep_mat_eva_stu_env_tim_all.filter(
            (x) =>
              this.evaluation.study_environments.includes(
                x.study_environment
              ) || x.hours > 0
          )
        );
      } else {
        this.$emit(
          "created",
          this.evaluation,
          this.tmp_ep_mat_eva_stu_env_tim_all.filter(
            (x) => x.study_environment == null || x.hours > 0
          )
        );
      }
    },
    update() {
      this.$emit("updated", this.evaluation);
    },
    changeStudyEnvironment() {
      if (isNaN(this.evaluation.id)) {
        if (this.evaluation.study_environments.length > 0) {
          this.tmp_ep_mat_eva_stu_env_tim_all =
            this.tmp_ep_mat_eva_stu_env_tim_all.filter((x) =>
              this.evaluation.study_environments.includes(x.study_environment)
            );
          this.key_ep_mat_eva_stu_env_tim_all++;
        } else {
          this.tmp_ep_mat_eva_stu_env_tim_all =
            this.tmp_ep_mat_eva_stu_env_tim_all.filter(
              (x) => x.study_environment == null
            );
          this.key_ep_mat_eva_stu_env_tim_all++;
        }
      }
    },
    createdEPMatStuEnvTimAll(tmp_ep_mat_eva_stu_env_tim_all) {
      if (!isNaN(this.evaluation.id)) {
        this.$restful
          .Post(
            `/mesh/egr-pro-mat-eva-stu-env-tim-all/`,
            tmp_ep_mat_eva_stu_env_tim_all
          )
          .then((response) => {
            this.EPMatEvaStuEnvTimAll.push(response);
            this.key_ep_mat_eva_stu_env_tim_all += 1;
          });
      } else {
        const index = this.tmp_ep_mat_eva_stu_env_tim_all.findIndex(
          (x) =>
            x.study_environment ==
              tmp_ep_mat_eva_stu_env_tim_all.study_environment &&
            x.time_allocation == tmp_ep_mat_eva_stu_env_tim_all.time_allocation
        );
        if (index != -1)
          this.tmp_ep_mat_eva_stu_env_tim_all[index] =
            tmp_ep_mat_eva_stu_env_tim_all;
        else
          this.tmp_ep_mat_eva_stu_env_tim_all.push(
            tmp_ep_mat_eva_stu_env_tim_all
          );
      }
    },
    UpdateEPMatStuEnvTimAll(tmp_ep_mat_eva_stu_env_tim_all) {
      if (!isNaN(this.evaluation.id)) {
        this.$restful
          .Put(
            `/mesh/egr-pro-mat-eva-stu-env-tim-all/${tmp_ep_mat_eva_stu_env_tim_all.id}/`,
            tmp_ep_mat_eva_stu_env_tim_all
          )
          .then((response) => {
            const index = this.EPMatEvaStuEnvTimAll.findIndex(
              (x) => x.id == response.id
            );
            if (index != -1) {
              this.EPMatEvaStuEnvTimAll.splice(index, 1, response);
              this.key_ep_mat_eva_stu_env_tim_all += 1;
            }
          });
      } else {
        const index = this.tmp_ep_mat_eva_stu_env_tim_all.findIndex(
          (x) => x.id == tmp_ep_mat_eva_stu_env_tim_all.id
        );
        if (index != -1) {
          this.tmp_ep_mat_eva_stu_env_tim_all.splice(
            index,
            1,
            tmp_ep_mat_eva_stu_env_tim_all
          );
        }
      }
    },
    deletedEPMatStuEnvTimAll(mat_eva_stu_env_tim_all_id) {
      if (!isNaN(mat_eva_stu_env_tim_all_id)) {
        this.$restful
          .Delete(
            `/mesh/egr-pro-mat-eva-stu-env-tim-all/${mat_eva_stu_env_tim_all_id}/`
          )
          .then(() => {
            const index = this.EPMatEvaStuEnvTimAll.findIndex(
              (x) => x.id == mat_eva_stu_env_tim_all_id
            );
            if (index != -1) {
              this.EPMatEvaStuEnvTimAll.splice(index, 1);
              this.key_ep_mat_eva_stu_env_tim_all += 1;
            }
          });
      }
    },
    fetchTeachingResource() {
      if (this.matter)
        this.$restful
          .Get(`/teaching/support-resource/?matter=${this.matter.matter}`)
          .then((response) => {
            this.teaching_resources = response;
          });
    },
    fetchTeachingResourceType() {
      this.$restful.Get(`/teaching/support-resource-type/`).then((response) => {
        this.teaching_resource_types = response;
      });
    },
    // fetchLearningExperiences() {
    //   this.$restful
    //     .Get(`/teaching/learning-experience/?matter=${this.matter.matter}`)
    //     .then((response) => {
    //       this.learning_experiences = response.sort(function (a, b) {
    //         if (a.order > b.order) return 1;
    //         if (a.order < b.order) return -1;
    //         return 0;
    //       });
    //       // this.selected_learning_experience = response[0].id;
    //     });
    // },
    changeEvaluationType(type) {
      if (type != 4) this.evaluation.teaching_support_resources = [];
      if (type == 1 || type == 2 || type == 4) {
        this.evaluation.weighing = 0;
      }
      if (
        this.institution.internal_use_id == "duoc_uc" ||
        this.$debug_change_duoc
      ) {
        if (type == 5) {
          this.evaluation.weighing = 40;
          this.evaluation.learning_experience = null;
        }
        if (
          this.matter &&
          this.matter.suggested_students > 0 &&
          type == 4 &&
          isNaN(this.evaluation.id)
        ) {
          this.evaluation.suggested_students = this.matter.suggested_students;
        }
      }
    },
    createSupportResource(text, resource_type) {
      if (this.matter.matter && resource_type.id != null) {
        let item = {
          name: text,
          description: text,
          type: resource_type.id,
          matter: this.matter.matter,
        };
        this.$restful
          .Post(`/teaching/support-resource/`, item)
          .then((response) => {
            this.teaching_resources.push(response);
            this.$bvModal.hide(`new-teaching-support-resource-modal`);
            this.$emit("created_support_resource", response);
            // this.evaluation.teaching_support_resources.push(response.id);
          });
      }
    },
    changeLearningExperience(learning_experience) {
      const learningExperience = this.learningExperiencesList.find(
        (x) => x.id == learning_experience
      );
      if (learningExperience) {
        const evaluation_criterias_micro = this.evaluationCriteriasMicros
          .filter((x) => learningExperience.study_units.includes(x.study_unit))
          .map((x) => x.id);
        this.evaluation.evaluation_criterias_micro =
          evaluation_criterias_micro.filter((x) =>
            this.evaluation.evaluation_criterias_micro.includes(x)
          );
      }
    },
    fetchEvaluationSituations() {
      this.$restful.Get(`/teaching/evaluation-situation/`).then((response) => {
        this.evaluation_situations = response;
      });
    },
    fetchEvaluativeAgentTypes() {
      this.$restful.Get(`/teaching/evaluative-agent/`).then((response) => {
        this.evaluative_agent_types = response;
      });
    },
  },
  mounted() {
    // TODO: Revisar si se recibe bien las props de evaluation_situations
    if (this.evaluation_situations.length == 0)
      this.fetchEvaluationSituations();
    // TODO: Revisar si se recibe bien las props de evaluative_agent_types
    if (this.evaluative_agent_types.length == 0)
      this.fetchEvaluativeAgentTypes();
    if (!isNaN(this.evaluation.id)) {
      let index = this.evaluation.evaluation_criterias_micro.findIndex(
        (cri_micro_id) =>
          !this.evaluationCriteriasMicros.find((x) => x.id == cri_micro_id)
      );
      while (index != -1) {
        this.evaluation.evaluation_criterias_micro.splice(index, 1);
        index = this.evaluation.evaluation_criterias_micro.findIndex(
          (cri_micro_id) =>
            !this.evaluationCriteriasMicros.find((x) => x.id == cri_micro_id)
        );
      }
      let index2 = this.evaluation.evaluation_criterias_macro.findIndex(
        (cri_macro_id) =>
          !this.evaluationCriteriasMacrosFilter.find(
            (x) => x.id == cri_macro_id
          )
      );
      while (index2 != -1) {
        this.evaluation.evaluation_criterias_macro.splice(index2, 1);
        index2 = this.evaluation.evaluation_criterias_macro.findIndex(
          (cri_macro_id) =>
            !this.evaluationCriteriasMacrosFilter.find(
              (x) => x.id == cri_macro_id
            )
        );
      }
    }
    if (!isNaN(this.egress_profile_matter_id)) {
      this.$store.dispatch(names.FETCH_EP_MATTER_TIME_ALLOCATIONS, {
        egress_profile_matter_id: this.egress_profile_matter_id,
      });
    }
    // if (this.matter) this.fetchLearningExperiences();
  },
  created() {
    this.$emit("fetch_ep_mat_eva_stu_env_tim_all");
    this.fetchTeachingResourceType();
    this.fetchTeachingResource();
  },
};
</script>

<style scoped src="@/utils/rich_text_editor.css">
</style>
<style scoped>
.btn-add {
  border: none;
  background: transparent;
  font-size: 13pt;
  padding: 0;
  margin-left: 0.7rem;
}
.evaluation-form-description {
  width: calc(100%);
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 0.875rem;
  min-height: calc(1.5em + 0.5rem + 2px);
  max-height: 5em;
  overflow-y: auto;
  padding: 0.25rem 1.2rem;
  line-height: 1.5;
}
.select-form {
  display: block !important;
}
.select-option-macro,
.select-option-micro {
  overflow-wrap: break-word;
  white-space: pre-wrap;
  padding: 0 0.25em;
  margin-left: 10px;
}
.select-option-micro {
  text-indent: 0px;
}
.custom-mr {
  margin-right: 0.05em;
}
</style>